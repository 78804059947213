import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  selectAllNotifications,
  selectShowSpecificNotificationsOnly,
  setActiveFlightIdScreen,
  setShowSpecificNotificationsOnly,
} from '../../redux/reducers/newsfeedReducer';
import {
  OverlayModule,
  selectOpenOverlay,
  setOpenOverlay,
} from '../../redux/reducers/overlayReducer';
import NewsfeedContainer from '../Newsfeed/NewsfeedContainer';
import ChatsContainer from '../Chat/ChatsContainer';
import TelephonyContainer from '../Telephony/Telephony';
import ETDSetting from '../ETDSetting/ETDSetting';
import Drawer from '../Drawer/Drawer';
import { useReadNotifications } from '../../utils/hooks/useReadNotifications';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { getAirlineKey, getFlightName } from '../../utils/helpers';
import {
  AIRLINE_COLOR_MAP,
  INITIAL_STATE,
  UTC_ZERO,
} from '../../utils/constants';
import { useGetProcess } from '../../utils/hooks/useGetProcess';
import BoardingTime from '../BoardingTime/BoardingTime';
import { usePreventHistoryChange } from '../../utils/hooks/usePreventHistoryChange';
import { calculateTime } from '../../utils/calculateTime';
import {
  select24Format,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import { ITimesObject } from '../../utils/types';
import { IataDelayCode } from '../../utils/generated/graphql';
import BriefingPackage from '../BriefingPackage/BriefingPackage';

const Overlay = () => {
  const openOverlay = useSelector(selectOpenOverlay);
  const dispatch = useDispatch();
  const readNotifications = useReadNotifications();
  const showSpecificNotificationsOnly = useSelector(
    selectShowSpecificNotificationsOnly
  );
  const { flight } = useParams();
  const notifications = useSelector(selectAllNotifications);
  const flightName = getFlightName(flight);
  const airlineKey = getAirlineKey(flightName);
  const airlineColor = AIRLINE_COLOR_MAP[airlineKey];

  const { data: dataHeader, loadingFirstTime: isLoadingHeader } =
    useGetProcess(flight);

  const {
    registration,
    actualOffBlockTime,
    departureTimes = { bestTimeUTC: INITIAL_STATE },
    bestTimeLogic = { timeLeft: INITIAL_STATE },
    etdDelay,
  } = dataHeader?.detailsHeader || {};

  const isUTC = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);
  const time = calculateTime(isUTC, is24Format, departureTimes as ITimesObject);

  usePreventHistoryChange(openOverlay !== null, () => {
    readNotifications(notifications, undefined);

    dispatch(setOpenOverlay(null));
    dispatch(setActiveFlightIdScreen(null));
  });

  let Child: any;
  let drawerProps: any;

  const onClose = () => {
    dispatch(setOpenOverlay(null));
    dispatch(setActiveFlightIdScreen(null));
  };

  if (
    openOverlay === OverlayModule.NOTIFICATIONS &&
    process?.env?.REACT_APP_TAC_NOTIFICATIONS_FEATURE_DISABLED?.toUpperCase() !==
    'TRUE'
  ) {
    const onNotificationClose = () => {
      onClose();

      if (showSpecificNotificationsOnly) {
        readNotifications(notifications, flight);
      }

      dispatch(setShowSpecificNotificationsOnly(false));
    };

    Child = <NewsfeedContainer handleCloseNotification={onNotificationClose} />;

    drawerProps = {
      drawerId: 'notification',
      isOpen: openOverlay === OverlayModule.NOTIFICATIONS,
      onClose: onNotificationClose,
    };
  }
  if (
    openOverlay === OverlayModule.CHAT &&
    process?.env?.REACT_APP_TAC_CHAT_FEATURE_DISABLED?.toUpperCase() !== 'TRUE'
  ) {
    Child = <ChatsContainer handleCloseChat={onClose} />;
    drawerProps = {
      drawerId: 'chat',
      isOpen: openOverlay === OverlayModule.CHAT,
      onClose,
      size: 'large',
    };
  }
  if (openOverlay === OverlayModule.TELEPHONY) {
    Child = <TelephonyContainer handleCloseTelephony={onClose} />;

    drawerProps = {
      drawerId: 'telephony',
      className: 'relative z-[55]',
      isOpen: openOverlay === OverlayModule.TELEPHONY,
      onClose,
      size: 'large',
    };
  }

  if (
    openOverlay === OverlayModule.BOARDING_TIME_UPDATE &&
    process.env.REACT_APP_TAC_BOARDING_TIME_FEATURE_DISABLED?.toUpperCase() !==
    'TRUE'
  ) {
    Child = (
      <>
        {!isLoadingHeader ? (
          <BoardingTime
            handleCloseBoardingTime={onClose}
            flightId={flight ?? ''}
            carrier={airlineKey.toLocaleUpperCase()}
            airlineColor={airlineColor}
          />
        ) : (
          <LoadingSpinner width={48} height={48} />
        )}
      </>
    );
    drawerProps = {
      drawerId: 'boardingTime',
      isOpen: openOverlay === OverlayModule.BOARDING_TIME_UPDATE,
      onClose,
      size: 'small',
      className: 'z-[55] fixed',
    };
  }

  if (openOverlay === OverlayModule.ETD_SETTINGS) {
    Child = (
      <>
        {!isLoadingHeader && departureTimes ? (
          <ETDSetting
            departureTimes={departureTimes}
            handleCloseEtdSettings={onClose}
            flightId={flight ?? ''}
            airlineColor={airlineColor}
            existingDelayCodes={(etdDelay?.codes as IataDelayCode[]) ?? []}
            initialDelay={
              bestTimeLogic?.delay
                ? bestTimeLogic?.delay?.toString()
                : null
            }
            disabledETD={actualOffBlockTime ? true : false}
            registration={registration ?? ''}
          />
        ) : (
          <LoadingSpinner width={48} height={48} />
        )}
      </>
    );
    drawerProps = {
      drawerId: 'etdSettings',
      isOpen: openOverlay === OverlayModule.ETD_SETTINGS,
      onClose,
      size: 'small',
      className: 'z-[55] fixed',
    };
  }

  if (openOverlay === OverlayModule.BRIEFING_PACKAGE) {
    Child = (
      <>
        {!isLoadingHeader ? (
          <BriefingPackage
            handleCloseBriefingPackage={onClose}
            flightId={flight ?? ''}
            airlineColor={airlineColor}
          />
        ) : (
          <LoadingSpinner width={48} height={48} />
        )}
      </>
    );
    drawerProps = {
      drawerId: 'briefingPackage',
      isOpen: openOverlay === OverlayModule.BRIEFING_PACKAGE,
      onClose,
      size: 'small',
      className: 'z-[55] fixed',
    };
  }

  return <Drawer {...drawerProps}>{Child}</Drawer>;
};

export default Overlay;
