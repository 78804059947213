import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import classNames from 'classnames';
import {
  EMPTY_STATE,
  ETD_SETTINGS,
  MINUTES_IN_DAY,
  ETD_TIME_FORMATTING,
  UPDATE_ETD_ERROR,
} from '../../utils/constants';
import TimePickerInput from '../BoardingTime/TimePickerInput';
import MenuHeader from '../Navigation/Header/MenuHeader/MenuHeader';
import { setIsMenuOpen } from '../../redux/reducers/headerReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  BoardingReason,
  IataDelayCode,
  TimeObject,
} from '../../utils/generated/graphql';
import { selectUTC } from '../../redux/reducers/settingsReducer';
import { getCarrierFromFlighId, getFlightName } from '../../utils/helpers';
import { setIsEtdSettingsOpen } from '../../redux/reducers/etdSettingsReducer';
import EtdReasons from './ETDReasons';
import { useGetDelayCodes } from '../../utils/hooks/useGetDelayCodes';
import { useUpdateEtd } from '../../utils/hooks/useUpdateEtd';
import moment from 'moment';
import { addError } from '../../redux/reducers/notificationReducer';

const textClassName =
  'flex items-center text-12 text-grey-40 dark:text-white font-body-text px-16';

const noReasonsTextClassName =
  'text-16 text-grey-40 dark:text-white font-body-text px-16 py-32 mx-auto';

interface IEtdSettings {
  departureTimes: TimeObject;
  handleCloseEtdSettings: () => void;
  flightId: string;
  airlineColor: string;
  initialDelay: string | null;
  existingDelayCodes: IataDelayCode[];
  disabledETD?: boolean;
  registration?: string;
}

export interface IReason extends BoardingReason {
  subcode: string;
  code: string;
  isSelected: boolean;
}

const mapIataCodesToReasons = (
  existingCodes: IataDelayCode[],
  delayCodes: IataDelayCode[] | null
): IReason[] => {
  if (delayCodes === null) return [];

  return existingCodes.map((el) => {
    const currentCode = el.subcode !== '' ? el.subcode : el.code;

    const reason = delayCodes.find(
      (reason) =>
        (reason.subcode !== '' ? reason.subcode : reason.code) === currentCode
    );

    return {
      id: currentCode,
      reason: reason?.description ?? EMPTY_STATE,
      isSelected: true,
      subcode: reason?.subcode ?? '',
      code: reason?.code ?? '',
    };
  });
};

export const getDayAndMonthForMoment = (flightId) => {
  const splittedId = flightId?.split('-') ?? [];
  return {
    month: parseInt(splittedId?.[0]?.substring(4, 6) ?? '') - 1,
    day: parseInt(splittedId?.[0]?.substring(6, 8) ?? ''),
  };
};

const EtdSetting = ({
  departureTimes,
  handleCloseEtdSettings,
  flightId,
  airlineColor,
  existingDelayCodes = [],
  initialDelay,
  disabledETD,
  registration,
}: IEtdSettings) => {
  const dispatch = useDispatch();

  const genericReasonId = '99';

  const carrier = getCarrierFromFlighId(flightId);
  const { data, loading } = useGetDelayCodes(carrier);

  const [etdUpdateReasons, setEtdUpdateReasons] = useState<IReason[]>([]);
  const [reasons, setReasons] = useState(data);

  const { onUpdateEtd, loading: isLoadingETD } = useUpdateEtd();

  useEffect(() => {
    !loading && setReasons(data);
  }, [data, loading]);

  useEffect(() => {
    setEtdUpdateReasons(mapIataCodesToReasons(existingDelayCodes, reasons));
  }, [reasons]);

  if (
    etdUpdateReasons.length > 1 &&
    etdUpdateReasons.at(-1)?.id === genericReasonId
  ) {
    const reasonsCopy = [...etdUpdateReasons];

    reasonsCopy.unshift(...reasonsCopy.splice(-1));

    setEtdUpdateReasons(reasonsCopy);
  }

  const selectedReasonsNo = etdUpdateReasons.filter(
    (reason) => reason.isSelected
  ).length;

  const [showReasonsList, setShowReasonsList] = useState(false);

  const isUTC = useSelector(selectUTC);
  const initialTimeString = (
    isUTC ? departureTimes.UTCTime : departureTimes.localTime
  )?.replace(' ', '');
  const initialTime = isUTC
    ? moment(initialTimeString).utc()
    : moment.parseZone(initialTimeString);
  const [etdTimeEstimated, setEtdTimeEstimated] = useState(initialTime);

  const buttonText = etdUpdateReasons.length
    ? ETD_SETTINGS.EDIT_REASONS
    : ETD_SETTINGS.ADD_REASONS;

  const delayMinutes = parseInt(initialDelay ?? '0');

  const calculatedMinutes = Math.max(
    etdTimeEstimated.diff(initialTime, 'minutes'),
    0
  );
  const calculatedDays = Math.max(
    etdTimeEstimated.diff(initialTime.clone().startOf('day'), 'days'),
    0
  );

  const delay =
    calculatedDays > 0 && !!calculatedMinutes
      ? MINUTES_IN_DAY + calculatedMinutes
      : delayMinutes + calculatedMinutes;

  const handleReasonListClose = () => {
    setShowReasonsList(false);
  };

  const buttonClassNames = classNames(
    'rounded-4 bg-green w-auto py-12 mx-[30px] mt-[18px] mb-32',
    {
      'bg-grey-12': !selectedReasonsNo || isLoadingETD || disabledETD,
    }
  );

  const addButtonClassNames = classNames(
    'rounded-4  w-auto py-12 mx-[30px] mt-[18px]',
    { 'bg-grey-12': disabledETD, 'bg-primary': !disabledETD }
  );

  const onConfirmButtonClick = async () => {
    const delayCodesArray = etdUpdateReasons?.map((codes) => {
      return {
        code: codes?.code ?? '',
        subcode: codes?.subcode ?? '',
      };
    });

    const result = await onUpdateEtd({
      flightId: flightId,
      delayCodes: delayCodesArray,
      registration: registration ?? '',
      standardDepartureTime: initialTime
        .clone()
        .utc()
        .format(ETD_TIME_FORMATTING),
      estimatedDepartureTime: etdTimeEstimated
        .clone()
        .utc()
        .format(ETD_TIME_FORMATTING),
      message: null,
    });

    if (result?.status && !result?.errors.length) {
      handleCloseEtdSettings();
    } else if (!result?.status && result?.errors.length) {
      result.errors.forEach((error) => dispatch(addError(error?.message)));
    } else if (!result?.status && !result?.errors.length) {
      dispatch(addError(UPDATE_ETD_ERROR));
    }
  };

  const renderReasonsList = () => {
    return (
      <div className="pb-[40px] overflow-y-scroll">
        {etdUpdateReasons.map((etdUpdateReason) => {
          return (
            <li
              key={etdUpdateReason.id}
              className={classNames(
                'list-none text-18 px-16 text-primary font-body-text border-b-1 border-b-grey-12 py-4 last:border-b-0 flex justify-between'
              )}>
              <input
                id={etdUpdateReason.id ?? ''}
                type="checkbox"
                className="hidden"
              />
              <div className="flex flex-row w-full">
                <label
                  htmlFor={etdUpdateReason.id ?? ''}
                  className="w-56 top-0 bottom-0 my-auto">
                  {etdUpdateReason.id}
                </label>
                <label htmlFor={etdUpdateReason.id ?? ''} className="w-full">
                  {etdUpdateReason.reason}
                </label>
              </div>
            </li>
          );
        })}
      </div>
    );
  };

  return (
    <div className="h-full overflow-y-clip flex flex-col relative dark:bg-grey-90">
      <MenuHeader
        className="tablet:hidden laptop:hidden desktop:hidden"
        themeColor={classNames(airlineColor, 'dark:bg-grey-90')}
        onMenuClick={() => {
          dispatch(setIsMenuOpen(true));
        }}
        hasBackArrow
        onBackArrowClick={() => {
          dispatch(setIsEtdSettingsOpen(false));
        }}
      />

      {showReasonsList ? (
        <EtdReasons
          handleCloseEtdSettings={handleReasonListClose}
          etdUpdateReasons={etdUpdateReasons}
          setSelectedUpdateReasons={setEtdUpdateReasons}
          delayReasons={data}
        />
      ) : (
        <>
          <div className="p-16 flex justify-between content-center items-center border-b-grey-12 border-b-1 pb-16">
            <div>
              <div className="text-14 text-primary dark:text-white font-body-text uppercase font-semibold">
                {ETD_SETTINGS.TITLE}
              </div>
              <div className="text-12 text-primary dark:text-white font-bold font-body-text">
                {getFlightName(flightId)}
              </div>
            </div>
            <Button
              text={ETD_SETTINGS.CANCEL}
              className="w-[110px] bg-grey-12 dark:bg-white rounded-4 h-44 flex items-center justify-center"
              textClassName="text-14 !text-primary font-body-text leading-[18px]"
              onClick={handleCloseEtdSettings}
            />
          </div>
          <TimePickerInput
            dateTime={etdTimeEstimated}
            setDateTime={setEtdTimeEstimated}
            delayMinutes={delay}
            delayDays={calculatedDays}
            disabledButtons={disabledETD}
          />

          <div className={textClassName}>{ETD_SETTINGS.DELAY_REASONS}</div>
          {selectedReasonsNo !== 0 ? (
            renderReasonsList()
          ) : (
            <div className={noReasonsTextClassName}>
              {ETD_SETTINGS.NO_REASONS_SELECTED}
            </div>
          )}

          <div className="w-full grid mt-auto mb-0">
            <Button
              text={buttonText}
              className={addButtonClassNames}
              textClassName={classNames(
                'font-body-text font-bold text-white text-14',
                {
                  'dark:text-grey-40': disabledETD,
                }
              )}
              onClick={() => setShowReasonsList(true)}
              disabled={disabledETD}
            />

            <Button
              text={ETD_SETTINGS.SEND}
              className={buttonClassNames}
              textClassName={classNames(
                'font-body-text font-bold text-white text-14 dark:text-grey-40',
                {
                  'dark:text-grey-40':
                    !selectedReasonsNo || disabledETD || isLoadingETD,
                }
              )}
              disabled={!selectedReasonsNo || isLoadingETD || disabledETD}
              onClick={onConfirmButtonClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EtdSetting;
